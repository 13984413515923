.cardStyle {
  background-color: rgba(72, 72, 72, 0.24) !important;
  border-radius: 1.5rem !important;
  border: none !important;
}
.cardHeading {
  font-size: 24px;
  text-align: left;
  color: #fff;
}

.imgStyle {
  width: 299px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.imgTxt {
  font-size: 18px;
  text-align: center;
  color: #C4C4C4;
  margin-top: 10px;
  margin-bottom: 15px;
}


@media only screen and (max-width: 600px) {

  .imgStyle {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  

}