.card {
  /* margin-top: 20px !important; */
  background-color: #242424 !important;
  border: none !important;
  border-radius: 12px !important;
  overflow-x: auto !important;
}
.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;

}
.smalContainer {
  display: flex;
  flex-direction: row;
}

.playIcon:hover {
  border-radius: 50px;
  background-color: rgba(72, 72, 72, 0.24);
}

.songname{
  color: white;
  font-size: 18px;
  width: 120px;
  margin: 0;
}
.txt {
  color: #bcbcbc;
  font-size: 18px;
  width: 120px;
  margin: 0;
  /* margin-left: 10px; */
}
.smalltxt {
  color: #bcbcbc;
  font-size: 14px;
  margin: 0;
}


@media only screen and (max-width: 600px) {

  .innerContainer {
    overflow: scroll;
  }

  .txt {
    display: none;
  }

  .icons {
    display: none;
  }

}
