.heading{
    margin: 0;
    font-size: 28px;
    color: #fff;
}
.txt{
    margin: 0;
    font-size: 18px;
    color: #C4C4C4;
}
.btnStyle{
    height: 40px !important;
    background-color: #212121 !important; 
    border-color: #C4C4C4 !important;
    border-radius: 6px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center !important;
}

.maincontainer {
    margin-left: 12.5rem;
}

.titlecard {
    display: "flex";
    flex-direction: "row";
    justify-content: "space-between";
    align-items: "center";
    margin-top: 20px;
    margin-bottom:  40px;
}

@media only screen and (max-width: 600px) {
    .maincontainer {
        margin-left: 0;
        margin-top: 8rem;
    }
}