.albumImg{
    height: 90px;
    width: 90px;
    border-radius: 8px;
}
.heading{
    margin: 0;
    font-size: 28px;
    color: #fff;
}
.txt{
    margin: 0;
    font-size: 18px;
    color: #C4C4C4;
}
.horizaontalLine {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 6px;
    border-radius: 7px;
    background-color: rgba(72, 72, 72, 0.24);
}

.titlecard {
    display: "flex";
    flex-direction: "row";
    justify-content: "space-between";
    align-items: "center";
    margin-top: 20px;
    margin-bottom:  40px;
}

.songtable {
    background-color: rgba(72, 72, 72, 0.24);
    border-radius: 1.5rem;
    padding: 16px;
    display: block;
    justify-content: center;
}

.maincontainer {
    margin-left: 12.5rem;
}

@media only screen and (max-width: 600px) {
    .maincontainer {
        margin-left: 0;
        margin-top: 8rem;
    }

    .songtable {
        background-color: rgba(72, 72, 72, 0.24);
        border-radius: 1.5rem;
        padding: 16px;
        display: block;
        justify-content: center;
    }

    .titlecard {
        display: "flex";
        flex-direction: "row";
        justify-content: "space-between";
        align-items: "center";
        margin-top: 0px;
        margin-bottom:  10px;
    }

    .albumImg{
        height: 63px;
        width: 63px;
    }
    
    .heading{
        margin: 0;
        font-size: 22px;
        color: #fff;
    }
    .txt{
        margin: 0;
        font-size: 14px;
        color: #C4C4C4;
    }


}