.firstCard {
  background-color: #242424 !important;
  border: none !important;
  border-radius: 8px !important;
  width: 600px !important;
  /* margin-bottom: 600px !important; */
}
.secondCard {
  background-color: #242424 !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 20px !important;
  width: 400px!important;
}
.thirdCard {
  margin-top: 20px !important;
  background-color: #242424 !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 20px !important;
  width: 400px !important;


}
.heading {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.txt {
  font-size: 16px;
  font-weight: 400;
  color: #bcbcbc;
  margin: 0;
}
.smallinputBox {
  margin-top: 10px !important;
  width: 100% !important;
  height: 50px !important;
  background-color: #242424 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-color: #565656 !important;
}
.inputBox {
  margin-top: 10px !important;
  height: 50px !important;
  background-color: #242424 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-color: #565656 !important;
  width: 100% !important;
}
.btnStyle {
  margin-top: 10px !important;
  height: 50px !important;
  background-color: #242424 !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100% !important;
  
}
.btnStyleDOF {
  margin-top: 10px !important;
  height: 50px !important;
  background-color: #242424 !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 25% !important;

}
.accBtn {
  border-radius: 6px !important;
  font-size: 14px !important;
  height: 40px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  color: #fff !important;
  text-transform:none !important;
}
.smallBox {
  width: 100;
  height: 139px;
  background-color: rgba(72, 72, 72, 0.24);
  border-radius: 8px;
  padding: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.boxHeading {
  text-align: center;
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}
.boxTxt {
  width: 235px;
  height: 49px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #bcbcbc;
}
.paymentTxt {
  font-size: 16px;
  font-weight: 500;
  color: #bcbcbc;
}
.creditTxt {
  font-size: 14px;
  font-weight: 500;
  color: #bcbcbc;
  margin: 0;
}

/* 
.headingUpload {
  font-size: 30px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}
.txtSong {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
} */
