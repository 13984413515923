.logoImg{
    height: 90px;
    width: 90px;
    border-radius: 8px;
}
.heading{
    margin: 0;
    font-size: 28px;
    color: #fff;
}
.txt{
    margin: 0;
    font-size: 18px;
    color: #C4C4C4;
}

.maincontainer {
    margin-left: 12.5rem;
}

@media only screen and (max-width: 600px) {
    .maincontainer {
        margin-left: 0;
        margin-top: 8rem;
    }
    
    .titlecard {
        display: "flex";
        flex-direction: "row";
        justify-content: "space-between";
        align-items: "center";
        margin-top: 0px;
        margin-bottom:  10px;
    }

    .logoImg{
        height: 63px;
        width: 63px;
    }
    .heading{
        margin: 0;
        font-size: 22px;
        color: #fff;
    }
    .txt{
        margin: 0;
        font-size: 14px;
        color: #C4C4C4;
    }
}
