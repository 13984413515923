.card {
  /* margin-top: 20px !important; */
  background-color: #242424 !important;
  border: none !important;
  border-radius: 12px !important;
  overflow-x: auto !important;


}
.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 965px !important;
  
}
.smalContainer {
  display: flex;
  flex-direction: row;

}
.txt {
  color: #bcbcbc;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  width: 120px;

  /* margin-left: 10px; */
}
.genTxt {
  color: #bcbcbc;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  /* margin-left: 10px; */
}
.smalltxt {
  color: #bcbcbc;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
