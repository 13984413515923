.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.heading {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.cardStyle {
  background-color: #242424 !important;
  border: none !important;
  border-radius: 8px !important;
  margin-top: 20px !important;
  padding: 0px !important;
}
.cardHeading {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}
.Cardtxt {
  font-size: 16px;
  font-weight: 400;
  color: #bcbcbc;
  margin: 0;
}
.smallinputBox {
  margin-top: 10px !important;
  width: 340px !important;
  height: 50px !important;
  background-color: #242424 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-color: #565656 !important;
}
.btnStyle {
  margin-top: 10px !important;
  width: 186px !important;
  height: 54px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #242424 !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.uploadButton {
  margin-top: 10px !important;
  height: 41px !important;
  width: 156px !important;
  color: #fff !important;
  border-radius: 6px !important;
  border: 1px solid #bcbcbc !important;
  background-color: #818181 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: center !important;
}
