.searchInput {
  background-color: #4848483d !important;
  border-radius: 8px !important;
  border: none !important;
  height: 62px !important;
}
.txt {
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #c4c4c4;
  cursor: pointer;
  margin: 0;
}
.txt:hover {
  color: #fff !important;
}
.txtActive {
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff !important;
  cursor: pointer;
  margin: 0;
}
.numContainer {
  height: 20px;
  width: 36px;
  border-radius: 18px;
  background-color: #4848483d;
  padding: 6px;
  margin-left: 10px;
}
.numTxt {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  color: #fff;
  margin: 0;
}
.container {
  display: flex;
  flex-direction: row;
  margin-right: 40px;
}
.horizaontalLine {
  margin-top: 20px;
  width: 100%;
  height: 6px;
  border-radius: 7px;
  background-color: #4848483d;
}
.heading {
  font-size: 28px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .maincontainer {
    margin-left: 0;
    margin-top: 8rem;
}
}