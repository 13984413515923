@font-face {
  font-family: "Jesa Script";
  font-style: normal;
  font-weight: 400;
  src: url("./JesaScript.otf") format("opentype");
}

.ant-table-placeholder {
  display: none;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 24px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.ant-select-dropdown {
  background-color: #242424 !important;
}

.ant-select-item-option-content {
  color: #fff !important;
  font-size: 16px !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #565656 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #2e2e2e !important;
}
.selectHegiht > .ant-select-selector {
  height: 43px !important;
  padding: 10px !important;
  background-color: transparent !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100px !important;
}

.ant-select-selector {
  height: 50px !important;
  padding: 10px !important;
  background-color: transparent !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
}

.ant-select-selector {
  color: white !important;
}

.ant-table-cell {
  background-color: rgba(72, 72, 72, 0.24) !important;
  border-bottom: none !important;
  color: #c4c4c4 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  align-items: center !important;
}

.ant-pagination-item {
  background-color: #242424 !important;
  color: #c4c4c4 !important;
  border: none !important;
}

.anticon {
  color: #c4c4c4 !important;
}

.ant-pagination-item-link {
  background-color: #242424 !important;
  border: none !important;
}

.ant-pagination-item-active a {
  color: #c4c4c4;
}

.ant-modal-header {
  background-color: #242424 !important;
  color: #c4c4c4 !important;
  border: none !important;
  border-radius: 0px !important;
}

.ant-modal-title {
  color: #c4c4c4 !important;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.ant-progress-text {
  color: #bcbcbc !important;
}

.MuiOutlinedInput-input {
  color: #ffffff !important;
  border-color: #c4c4c4 !important;
}

.MuiButtonBase-root {
  color: #c4c4c4 !important;
}
.MuiAutocomplete-tag {
  background-color: #565656 !important;
  color: #c4c4c4 !important;
}

.MuiAutocomplete-listbox {
  background-color: #363636 !important;
  color: #fff !important;
}
.MuiList-root {
  background-color: #363636 !important;
}
.MuiSelect-select {
  padding: 12px !important;
  color: #fff !important;
}

.MuiSelect-icon {
  color: #fff !important;
}

/* Tabs Css */
.ant-tabs-tab:hover {
  background-color: #1c1c1c !important;
  color: #fff !important;
}

.ant-tabs-tab-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ant-tabs-tab {
  width: 15rem !important;
  margin: 0px !important;
}

.ant-tabs-tab-active {
  background-color: #1c1c1c !important ;
}

.ant-tabs-nav-wrap {
  border-bottom: 1px solid #1f1f1f !important;
}

/* Table Css */

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: rgba(72, 72, 72, 0.24) !important;

  transition: 0ms;
}

.ant-table-tbody {
  border-radius: 10px;
  background-color: #212121;
}

.ant-input-affix-wrapper > input.ant-input {
  background-color: transparent !important;
  color: #c4c4c4 !important;
}

label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: 1px solid #fff !important;
  border-color: red !important;
}
label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:hover {
  border-color: blue !important;
}
.ant-modal-body {
  background-color: #171717 !important;
  padding: 20px 60px !important;
}
.bar__progress {
  flex: 1 1;
  border-radius: 5px;
  margin: 0 20px;
  height: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 500px;
}
.bar__progress__knob {
  position: relative;
  height: 16px;
  width: 16px;
  border: 1.5px solid white;
  border-radius: 50%;
  background-color: orange;
}

@media only screen and (max-width: 600px) {
  .ant-tabs-nav {
    position: fixed !important;
    width: 100%;
    z-index: 1;
  }

  .ant-tabs-tab {
    height: 3rem !important;
    width: 100% !important;
    margin: 0px !important;
    margin-top: 4rem !important;
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px !important;
    padding-inline: 15px;
  }

  .ant-tabs-tab-active {
    background-color: #1f1f1f !important;
  }
}
