.box {
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: 693px;
  height: 520px;
  background-color: #171717;
  border-radius: 1.5rem;
  backdrop-filter: blur(500px);
  padding-left: 80px;
  padding-right: 80px;
}

.boxShare {
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  height: 258px;
  width: 480px;
  background-color: #171717;
  border-radius: 20px;
  backdrop-filter: blur(500px);
}

.heading {
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
.smallTxt {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.shareSmalltxt {
  font-size: 11px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.txt {
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.horizaontalLine {
  margin-top: 15px;
  width: 100%;
  height: 6px;
  border-radius: 7px;
  background-color: #4848483d;
}
.toggleStyle {
  border-radius: 6px !important;
}
.btn {
  font-size: 22px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
  text-align: center;
  height: 38px !important;
  width: 256px !important;
  background-color: #00a3ff !important;
  border-radius: 6px !important;
}
.shareContainer {
  height: 160px;
  width: 449px;
  border-radius: 15px;
  background-color: #4848483d;
  margin-top: 15px;
  padding: 20px;
}
.playContainer {
  height: 160px;
  width: 449px;
  border-radius: 15px;
  background-color: #4848483d;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.copyContainer {
  height: 36px;
  width: 412px;
  border-radius: 4px;
  background-color: #4848483d;
  padding: 3px;
  margin-top: 20px;
}
.txtBtn {
  color: #00a3ff !important;
}
.linktxt {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin: 0;
  margin-left: 10px;
  margin-right: 40px;
}
.playTxt {
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #c4c4c4;
  margin-top: 20px;
  cursor: pointer;
}
.inputBox {
  margin-top: 10px !important;
  height: 50px !important;
  background-color: #242424 !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-color: #565656 !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}

/* PlanModal CSS */
.planBox {
  height: 700px;
  width: 1143px;
  border-radius: 10px;
  position: absolute;
  padding: 20px 60px;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  background-color: #171717;
  backdrop-filter: blur(500px);
}
.innerplanBox {
  margin-top: 10px;
  height: 600px;
  width: 300px;
  border-radius: 8px;
  background-color: #4848483d;
  padding: 20px 40px;
}
.innerPlanheading {
  color: #fff;
  font-size: 38px;
  margin: 0;
  font-weight: 400;
  text-align: center;
}
.smallInnerPlantxt {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #bcbcbc;
}
.simpleTxtPlan {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  text-align: left;
}

/* Sign Modal */
.signInputBox {
  margin-top: 0px !important;
  height: 50px !important;
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-color: #565656 !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}
.smallinputSignBox {
  margin-top: 10px !important;
  width: 375px !important;
  height: 50px !important;
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 5px !important;
  border-color: #565656 !important;
}
.signTxt {
  font-size: 16px;
  font-weight: 400;
  color: #bcbcbc;
  margin: 0;
}
.btnStyle {
  margin-top: 10px !important;
  height: 50px !important;
  background-color: transparent !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100% !important;
}
.btnStyleDOF {
  margin-top: 10px !important;
  height: 50px !important;
  background-color: transparent !important;
  border-color: #565656 !important;
  border-radius: 5px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 50% !important;
}
.selectStyle {
  border-color: #565656 !important;

  margin-top: 10px !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 50% !important;
}
