.heading {
  margin-top: 10px;
  font-size: 28px;
  font-weight: normal;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .maincontainer {
    margin-left: 0;
    margin-top: 8rem;
}
}