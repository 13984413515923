.albumImg {
  height: 80px;
  width: 80px;
  border: 2px solid #fff;
  border-radius: 6px;
}
.heading {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}
.txt {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #c4c4c4;
}
.btnStyle {
  height: 40px !important;
  background-color: #212121 !important;
  border-color: #c4c4c4 !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
}

@media only screen and (max-width: 600px) {
  .maincontainer {
    margin-left: 0;
    margin-top: 8rem;
}
}