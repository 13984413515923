.container {
  position: fixed !important;
  background-color: #171717;
  overflow: none;
  height: 100%;
  z-index: 10;
}

.innerContainer {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.innerContainer li {
  display: flex;
  justify-content: left;
  padding-left: 2rem;
  align-items: center;
  height: 4rem;
  color: #fff;
  text-decoration: none;
  filter: grayscale(100%);
  transition: 400ms;
  width: 100%;
}

.innerContainer li:hover {
  background: #1C1C1C;
  color: #fff;
  transition: 400ms;
}

.text {
  color: #BCBCBC;
  font-size: 16px;
  position: absolute;
  display: flex;
  padding-left: 3.5rem;
}

.logo {
  color: #FFFFFF;
  font-size: 20px;
}

.logo svg {
  width: 15rem;
  height: auto;
}

.logoTxt {
  display: inline;
  color: #FFFFFF;
  position: absolute;
  display: flex;
  padding-left: 2.5rem;
}

.navLink svg {
  width: 1.8rem;
  height: 1.8rem;
}

.navActiveLink li {
  filter: grayscale(0%) opacity(1); 
}

.navActiveLink li .text{
  color: #FFFFFF;
}



@media only screen and (min-width: 600px) {
  .container {
    width: 12.5rem;
  }

  .container .text {
    display: inline;
    font-size: 16px;
  }

  .container .logo svg
  {
    margin-right: 1rem;
  }

  .hamburgermenu {
    background-color: red;
    display: none;
  }

}

@media only screen and (max-width: 600px) {

  .hamburgermenu {
    position: fixed;
    left: 1rem;
    z-index: 1;
  }

  .SearchIcon {
    position: fixed;
    right: 1rem;
    z-index: 1;
  }

  .container {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    height: 4rem !important;
  }

  .innerContainer li:hover {
    background: none;
    color: #fff;
    transition: 400ms;
  }

  .container .logo {
    justify-content: center;
  }

  .innerContainer {
    flex-direction: row;
  }

  .navLink {
    justify-content: center;
  }

  main {
    margin: auto;
    position: relative;
  }

  .container .navItem {
    display: none;
  }

  .container .text {
    display: none;
  }

  .innerContainer li {
    display: flex;
    justify-content: left;
    padding-left: 0;
    align-items: center;
    height: 4rem;
  }

  .logo svg {
    width: 10rem;
  }
  


}