.topCard{
    background-color: rgba(72, 72, 72, 0.24) !important;
    border-radius: 1.5rem !important;
    border: none !important;
    margin: 100px;
}

.img{
    height: 168px;
    width: auto;
    border-radius: 10px;
    position: relative;
    margin-right: 10px ;
    margin-left: 10px ;
}

.heading{
    font-size: 24px;
    color: #fff;
}
.musicTxt{
    padding-top: 15px;
    font-size: 18px;
    text-align: center;
    color: #C4C4C4;
}

@media only screen and (max-width: 600px) {
    .img{
        height: 110px;
        width: auto;
        border-radius: 10px;
        position: relative;
        margin-right: 5px ;
    }
}