.footerStyle {
  background-color: #171717 !important;
  padding: 0 !important;
  z-index: 10 !important;
  overflow-x: auto !important;
}
.Container {
  background-color: #1C1C1C;
  padding: 10px;
}
.innerContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}
.img {
  height: 50px;
  width: 50px;
  margin-left: 12px;
}
.songName {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.artistName {
  font-size: 12px;
  color: #c4c4c4;
  margin: 0;
}
.selectStyle {
  border-color: #565656 !important;
  color: #bcbcbc !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  width: 100px !important;
}
.sliderBarVol {
  width: 100px;
}
.sliderBar {
  width: 400px;
  margin-left: 10px;
}
.nameStyles{
    margin-left: 10px;
    width: 140px;
}
@media only screen and (max-width: 600px) {
  .footerStyle {
    bottom: 0 !important;
  }

  .Container {
    background-color: #171717;
    width: 100% !important;
  }
  .img {
    height: 50px;
    width: 50px;
    margin-left: 12px;
  }
  .songName {
    font-size: 14px;
    color: #fff;
    margin: 0;
  }
  .artistName {
    font-size: 12px;
    color: #c4c4c4;
    margin: 0;
  }
  .iconhide {
    display: none;
  }
  .sliderBarVol {
    display: none;
  }
  .sliderBar {
    display: none;
  }
  .nameStyles{
    margin-left: 10px;
    width: 80%px;
  }
  .iconChangeStyle{
    height: 20px;
    width: 20px;
  }
  .songTime {
    display: none;
  }

  .volumebutton {
    display: none;
  }

}
