.cardStyle {
  background-color: rgba(72, 72, 72, 0.24) !important;
  border: none !important;
  border-radius: 1.5rem !important;
  padding: 20px !important;
  
}

.infocontainer {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.heading {
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  margin-top: 30px;
}
.txt {
  /* margin-top: 10px; */
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  width: 90%;
  height: 36px;
}
.smallTxt {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.iconBox {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 20rem;

    background: var(--bg-line);
    border-radius: 8px; 
    margin-top: 10px;
    background-color: rgba(72, 72, 72, 0.24);
}
.link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 4rem;
  text-decoration: none;
  border-radius: 8px;
  width: 100%;
}

.link:hover {
  filter: grayscale(0%) opacity(1);
  transition: 200ms;
  background-color: rgba(72, 72, 72, 0.24);
}

.playlistImg {
  width: 380px;
  height: 380px;
  border-radius: 1rem;
}


